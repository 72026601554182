import * as React from "react";
import Container from "../../components/layouts/Container";
import SummarizeIcon from "@mui/icons-material/Summarize";
import JoinInnerIcon from "@mui/icons-material/JoinInner";
import ReportIcon from "@mui/icons-material/Report";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";  
import config from "../../config/config";


import {
  SaveButton,
  ReferenceInput,
  SimpleForm,
  Toolbar,
  usePermissions,
  useGetOne,
  useRecordContext,
  TextField,
  SimpleShowLayout,
  RecordContextProvider,
  ReferenceField,
  EmailField,
  DateField,
  SelectField,
  BooleanField,
  ReferenceArrayField,
  ChipField,
  SingleFieldList,
  Datagrid,
  NumberField,
  Pagination,
  FunctionField,
} from "react-admin";
import { useFormContext } from 'react-hook-form';
import dataProvider from "../../api/dataProvider";
import CustomItemLabeled from "../../components/fields/CustomItemLabeled";
import Row from "../../components/layouts/Row";
import CustomCard from "../../components/cards/CustomCard";
import Item from "../../components/layouts/Item";
import CustomImageField from "../../components/CustomImageField";
import Column from "../../components/layouts/Column";
import CustomNestedList from "../../components/lists/CustomNestedList";

const Comparison = () => {

  const { permissions } = usePermissions();
  const [userA, setUserA] = React.useState();
  const [userB, setUserB] = React.useState();

  const AnalyseButton = () => {
    const { getValues } = useFormContext();
    const onClick = async e => {
      e.preventDefault();
      const { id } = getValues();
      const {data} = await dataProvider.getOne('users', { id: id });
      console.log(data);
      if (!userA) setUserA(data);
      else if (!userB) setUserB(data);
      else {
        setUserA(data);
        setUserB(null);
      }
    };
    if (!userB)
      return (
          <SaveButton type="button" onClick={onClick} icon={<SummarizeIcon/> } label="Compare" />
      );
    else
      return (
        <SaveButton type="button" onClick={onClick} icon={<SummarizeIcon/> } label="Analyse" />
      ); 
  };

  const ComparisonToolbar = () => (
    <Toolbar>
       <AnalyseButton size="large" title="test"/>
    </Toolbar>
  );

  // const record = useRecordContext(user);
  // console.log(record);
  console.log(userA, userB);
  return (
    <Container sx={{ marginTop: 5 }}>
      <Row>
        <SimpleForm resource="users" toolbar={<ComparisonToolbar/>}>
          <ReferenceInput
            label="Choose a user"
            source="id"
            reference="users"
            alwaysOn
          />
        </SimpleForm>
      </Row>
      <Row>
      <RecordContextProvider value={userA}>
        <SimpleShowLayout>
            <Row>
            <h1>Profile A</h1>
            <Container p={2}>
            <Column xs={12} sm={12} md={9}>
              <Row>
                <Item>
                  <CustomCard
                    link={"4"}
                    source="userA"
                    resource="reports"
                    label="reports"
                    icon={<ReportIcon htmlColor="white" />}
                  />
                </Item>
                <Item>
                  <CustomCard
                    link={"1"}
                    source="user"
                    resource="user-plans"
                    label="plans"
                    icon={<LocalActivityIcon htmlColor="white" />}
                  />
                </Item>
                <Item>
                  <CustomCard
                    link={"2"}
                    source="users"
                    resource="matches"
                    label="matches"
                    icon={<JoinInnerIcon htmlColor="white" />}
                  />
                </Item>
                <Item>
                  <CustomCard
                    link={"3"}
                    source="users"
                    resource="meetings"
                    label="meetings"
                    icon={<JoinInnerIcon htmlColor="white" />}
                  />
                </Item>
              </Row>
              <Row>
                {/* <Item>
                <Labeled>
                  <TextField source="id" fullWidth />
                </Labeled>
              </Item> */}
                <CustomItemLabeled
                  itemProps={{ sx: { marginBottom: 2 }, md: 9 }}
                >
                  <TextField
                    fontSize={40}
                    fontWeight={"bold"}
                    source="username"
                    fullWidth
                    emptyText="-"
                  />
                </CustomItemLabeled>
              </Row>
              <Row>
                <CustomItemLabeled>
                  <ReferenceField
                    link="show"
                    label="Institution"
                    source="institution.id"
                    reference="institutions"
                    emptyText="-"
                  />
                </CustomItemLabeled>
              </Row>
              <Row>
                <CustomItemLabeled>
                  <TextField source="role" emptyText="-" />
                </CustomItemLabeled>
                <CustomItemLabeled>
                  <TextField source="locale" emptyText="-" />
                </CustomItemLabeled>
              </Row>
              <Row>
                <CustomItemLabeled>
                  <EmailField source="email" emptyText="-" />
                </CustomItemLabeled>
                <CustomItemLabeled>
                  <TextField source="name" emptyText="-" />
                </CustomItemLabeled>
                <CustomItemLabeled>
                  <TextField source="surname" emptyText="-" />
                </CustomItemLabeled>
                <CustomItemLabeled>
                  <TextField source="dni" emptyText="-" />
                </CustomItemLabeled>
                <CustomItemLabeled>
                  <DateField source="birthdate" fullWidth emptyText="-" />
                </CustomItemLabeled>
                {/* <CustomItemLabeled>
                  <EmailField source="phonenumber" emptyText="-" />
                </CustomItemLabeled> */}
                {/* <CustomItemLabeled>
                  <TextField source="city" emptyText="-" />
                </CustomItemLabeled> */}
              </Row>

              <Row>
                <CustomItemLabeled>
                  <SelectField
                    source="gender"
                    choices={[
                      { id: "M", name: "Male" },
                      { id: "F", name: "Female" },
                      { id: "NB", name: "Not binary" },
                      { id: "CM", name: "Cis men" },
                      { id: "CF", name: "Cis women" },
                      { id: "TM", name: "Transgender men" },
                      { id: "TF", name: "Transgender women" },
                      { id: "TSM", name: "Transexual men" },
                      { id: "TSF", name: "Transexual women" },
                      { id: "FL", name: "Fluid" },
                    ]}
                    emptyText="-"
                  />
                </CustomItemLabeled>

                {/* <Item>
                <CustomItemLabeled>
                  <SelectField
                    source="orientation"
                    choices={[
                      { id: "HET", name: "Heterosexual" },
                      { id: "HOM", name: "Homosexual" },
                      { id: "BIS", name: "Bisexual" },
                      { id: "AS", name: "Asexual" },
                      { id: "OTHERS", name: "Others" },
                    ]}
                    emptyText="-"
                  />
                </CustomItemLabeled>
              </Item> */}

                <CustomItemLabeled>
                  <SelectField
                    source="genderPreference"
                    choices={[
                      { id: "M", name: "Male" },
                      { id: "F", name: "Female" },
                      { id: "B", name: "Both" },
                    ]}
                    emptyText="-"
                  />
                </CustomItemLabeled>
              </Row>
              <Row>
                <CustomItemLabeled>
                  <SelectField
                    source="group"
                    choices={[
                      { id: "1", name: "1" },
                      { id: "2", name: "2" },
                      { id: "3", name: "3" },
                      { id: "4", name: "4" },
                    ]}
                    fullWidth
                  />
                </CustomItemLabeled>
              </Row>
              <Row>
                <CustomItemLabeled>
                  <TextField source="bio" fullWidth emptyText="-" />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <TextField source="workplace" emptyText="-" />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <TextField source="education" emptyText="-" />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <TextField source="formationCenter" emptyText="-" />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <TextField source="searchRadius" emptyText="-" />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <TextField
                    source="contactPerson.fullname"
                    fullWidth
                    emptyText="-"
                  />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <TextField
                    source="contactPerson.email"
                    fullWidth
                    emptyText="-"
                  />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <TextField
                    source="contactPerson.phonenumber"
                    fullWidth
                    emptyText="-"
                  />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <BooleanField
                    source="contactPerson.isLegalGuardian"
                    label={"Is legal guardian?"}
                    emptyText="-"
                    fullWidth
                  />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <TextField
                    source="personalSituation"
                    fullWidth
                    emptyText="-"
                  />
                </CustomItemLabeled>
                {/* <CustomItemLabeled>
                  <TextField source="autonomy" fullWidth emptyText="-" />
                </CustomItemLabeled> */}

                {/* <CustomItemLabeled>
                  <TextField source="socialRelations" fullWidth emptyText="-" />
                </CustomItemLabeled> */}

                {/* <CustomItemLabeled>
                  <TextField source="technology" fullWidth emptyText="-" />
                </CustomItemLabeled> */}

                <CustomItemLabeled>
                  <TextField source="health" fullWidth emptyText="-" />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <TextField
                    source="personalArea"
                    fullWidth
                    emptyText="-"
                  />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <TextField source="movilityArea" fullWidth emptyText="-" />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <TextField source="economicArea" fullWidth emptyText="-" />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <TextField source="technologyArea" fullWidth emptyText="-" />
                </CustomItemLabeled>

                {/* <CustomItemLabeled>
                  <TextField
                    source="personalSituation"
                    fullWidth
                    emptyText="-"
                  />
                </CustomItemLabeled> */}
              </Row>
              {/* <Row>
              <CustomItemLabeled>
                <ArrayField source="observations" fullWidth>
                  <Datagrid
                    bulkActionButtons={false}
                    sort={{ field: "createdAt", order: "DESC" }}
                    fullWidth
                  >
                    <TextField
                      sx={{ marginY: 2 }}
                      source="text"
                      helperText={false}
                      rows={4}
                      fullWidth
                      emptyText="-"
                    />
                    <DateField source={"createdAt"} emptyText="-" />                   
                  </Datagrid>
                </ArrayField>
              </CustomItemLabeled>
            </Row>
            <Row>
              <CustomItemLabeled>
                <ArrayField source="comments" fullWidth>
                  <Datagrid bulkActionButtons={false}>
                    <TextField
                      sx={{ marginY: 2 }}
                      source="text"
                      helperText={false}
                      rows={4}
                      fullWidth
                    />
                    <DateField source={"createdAt"} />                   
                  </Datagrid>
                </ArrayField>
              </CustomItemLabeled>
            </Row> */}

              <Row>
                {/* <CustomItemLabeled>
                  <BooleanField source="avastTest" />
                </CustomItemLabeled> */}

                <CustomItemLabeled>
                  <BooleanField source="sexualTest.isCompleted" />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <BooleanField source="sexualTest.anathomyScore" />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <BooleanField source="sexualTest.relationshipsScore" />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <BooleanField source="sexualTest.preventionScore" />
                </CustomItemLabeled>
              </Row>
              <Row>
                <CustomItemLabeled>
                  <BooleanField
                    source="imageRightsConsentAccepted"
                    label="imageRightsConsentAccepted"
                  />
                </CustomItemLabeled>
              </Row>
              { permissions==='admin'?
              <Row>
                <CustomItemLabeled>
                  <BooleanField
                    source="legalTermsAccepted"
                    label="legalTermsAccepted"
                  />
                </CustomItemLabeled>
                <CustomItemLabeled>
                  <BooleanField
                    source="legalTermsAccepted"
                    label="legalTermsAccepted"
                  />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <BooleanField
                    source="isAutogeneratedPassword"
                    label="isAutogeneratedPassword"
                  />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <BooleanField
                    source="accessRequiredInfo"
                    label="accessRequiredInfo"
                  />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <BooleanField
                    source="visibility"
                    label="visibility"
                  />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <BooleanField
                    source="enabled"
                    label="enabled"
                  />
                </CustomItemLabeled>
              
                <CustomItemLabeled>
                  <DateField source="createdAt" />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <DateField source="updatedAt" />
                </CustomItemLabeled>
              </Row> : null }
            </Column>
            <Column xs={12} sm={12} md={3}>
              <Row>
                <Item xs={12} sm={12} md={12}>
                  <CustomImageField
                    sx={{ width: "100%", marginBottom: 16, borderRadius: 8 }}
                    source="avatar"
                    size="medium"
                    title="avatar"
                  />
                </Item>
                <Item xs={4} sm={4} md={4}>
                  <CustomImageField
                    sx={{ width: "100%", marginBottom: 16, borderRadius: 8 }}
                    source="photos.0"
                    size="medium"
                    title="photos.0"
                  />
                </Item>
                <Item xs={4} sm={4} md={4}>
                  <CustomImageField
                    sx={{ width: "100%", marginBottom: 16, borderRadius: 8 }}
                    source="photos.1"
                    size="medium"
                    title="photos.1"
                  />
                </Item>
                <Item xs={4} sm={4} md={4}>
                  <CustomImageField
                    sx={{ width: "100%", marginBottom: 16, borderRadius: 8 }}
                    source="photos.2"
                    size="medium"
                    title="photos.2"
                  />
                </Item>
              </Row>
              <Row>
                <Item>
                  {/* <ArrayField source="interests">
                  <SingleFieldList linkType="show" resource="interests">
                    <ChipField source={`label.${config.defaultLocale}`} />
                  </SingleFieldList>
                </ArrayField> */}
                  <ReferenceArrayField source="interests" reference="interests">
                    <SingleFieldList linkType="show">
                      <ChipField source={`label.${config.defaultLocale}`} />
                    </SingleFieldList>
                  </ReferenceArrayField>
                </Item>
              </Row>
            </Column>
          </Container>
        </Row>
        </SimpleShowLayout>
      </RecordContextProvider>
      <RecordContextProvider value={userB}>  
        <SimpleShowLayout>
        <Row>
            <h1>Profile B</h1>
            <Container p={2}>
            <Column xs={12} sm={12} md={9}>
              <Row>
                <Item>
                  <CustomCard
                    link={"4"}
                    source="userB"
                    resource="reports"
                    label="reports"
                    icon={<ReportIcon htmlColor="white" />}
                  />
                </Item>
                <Item>
                  <CustomCard
                    link={"1"}
                    source="user"
                    resource="user-plans"
                    label="plans"
                    icon={<LocalActivityIcon htmlColor="white" />}
                  />
                </Item>
                <Item>
                  <CustomCard
                    link={"2"}
                    source="users"
                    resource="matches"
                    label="matches"
                    icon={<JoinInnerIcon htmlColor="white" />}
                  />
                </Item>
                <Item>
                  <CustomCard
                    link={"3"}
                    source="users"
                    resource="meetings"
                    label="meetings"
                    icon={<JoinInnerIcon htmlColor="white" />}
                  />
                </Item>
              </Row>
              <Row>
                {/* <Item>
                <Labeled>
                  <TextField source="id" fullWidth />
                </Labeled>
              </Item> */}
                <CustomItemLabeled
                  itemProps={{ sx: { marginBottom: 2 }, md: 9 }}
                >
                  <TextField
                    fontSize={40}
                    fontWeight={"bold"}
                    source="username"
                    fullWidth
                    emptyText="-"
                  />
                </CustomItemLabeled>
              </Row>
              <Row>
                <CustomItemLabeled>
                  <ReferenceField
                    link="show"
                    label="Institution"
                    source="institution.id"
                    reference="institutions"
                    emptyText="-"
                  />
                </CustomItemLabeled>
              </Row>
              <Row>
                <CustomItemLabeled>
                  <TextField source="role" emptyText="-" />
                </CustomItemLabeled>
                <CustomItemLabeled>
                  <TextField source="locale" emptyText="-" />
                </CustomItemLabeled>
              </Row>
              <Row>
                <CustomItemLabeled>
                  <EmailField source="email" emptyText="-" />
                </CustomItemLabeled>
                <CustomItemLabeled>
                  <TextField source="name" emptyText="-" />
                </CustomItemLabeled>
                <CustomItemLabeled>
                  <TextField source="surname" emptyText="-" />
                </CustomItemLabeled>
                <CustomItemLabeled>
                  <TextField source="dni" emptyText="-" />
                </CustomItemLabeled>
                <CustomItemLabeled>
                  <DateField source="birthdate" fullWidth emptyText="-" />
                </CustomItemLabeled>
                {/* <CustomItemLabeled>
                  <EmailField source="phonenumber" emptyText="-" />
                </CustomItemLabeled> */}
                {/* <CustomItemLabeled>
                  <TextField source="city" emptyText="-" />
                </CustomItemLabeled> */}
              </Row>

              <Row>
                <CustomItemLabeled>
                  <SelectField
                    source="gender"
                    choices={[
                      { id: "M", name: "Male" },
                      { id: "F", name: "Female" },
                      { id: "NB", name: "Not binary" },
                      { id: "CM", name: "Cis men" },
                      { id: "CF", name: "Cis women" },
                      { id: "TM", name: "Transgender men" },
                      { id: "TF", name: "Transgender women" },
                      { id: "TSM", name: "Transexual men" },
                      { id: "TSF", name: "Transexual women" },
                      { id: "FL", name: "Fluid" },
                    ]}
                    emptyText="-"
                  />
                </CustomItemLabeled>

                {/* <Item>
                <CustomItemLabeled>
                  <SelectField
                    source="orientation"
                    choices={[
                      { id: "HET", name: "Heterosexual" },
                      { id: "HOM", name: "Homosexual" },
                      { id: "BIS", name: "Bisexual" },
                      { id: "AS", name: "Asexual" },
                      { id: "OTHERS", name: "Others" },
                    ]}
                    emptyText="-"
                  />
                </CustomItemLabeled>
              </Item> */}

                <CustomItemLabeled>
                  <SelectField
                    source="genderPreference"
                    choices={[
                      { id: "M", name: "Male" },
                      { id: "F", name: "Female" },
                      { id: "B", name: "Both" },
                    ]}
                    emptyText="-"
                  />
                </CustomItemLabeled>
              </Row>
              <Row>
                <CustomItemLabeled>
                  <SelectField
                    source="group"
                    choices={[
                      { id: "1", name: "1" },
                      { id: "2", name: "2" },
                      { id: "3", name: "3" },
                      { id: "4", name: "4" },
                    ]}
                    fullWidth
                  />
                </CustomItemLabeled>
              </Row>
              <Row>
                <CustomItemLabeled>
                  <TextField source="bio" fullWidth emptyText="-" />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <TextField source="workplace" emptyText="-" />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <TextField source="education" emptyText="-" />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <TextField source="formationCenter" emptyText="-" />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <TextField source="searchRadius" emptyText="-" />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <TextField
                    source="contactPerson.fullname"
                    fullWidth
                    emptyText="-"
                  />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <TextField
                    source="contactPerson.email"
                    fullWidth
                    emptyText="-"
                  />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <TextField
                    source="contactPerson.phonenumber"
                    fullWidth
                    emptyText="-"
                  />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <BooleanField
                    source="contactPerson.isLegalGuardian"
                    label={"Is legal guardian?"}
                    emptyText="-"
                    fullWidth
                  />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <TextField
                    source="personalSituation"
                    fullWidth
                    emptyText="-"
                  />
                </CustomItemLabeled>
                {/* <CustomItemLabeled>
                  <TextField source="autonomy" fullWidth emptyText="-" />
                </CustomItemLabeled> */}

                {/* <CustomItemLabeled>
                  <TextField source="socialRelations" fullWidth emptyText="-" />
                </CustomItemLabeled> */}

                {/* <CustomItemLabeled>
                  <TextField source="technology" fullWidth emptyText="-" />
                </CustomItemLabeled> */}

                <CustomItemLabeled>
                  <TextField source="health" fullWidth emptyText="-" />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <TextField
                    source="personalArea"
                    fullWidth
                    emptyText="-"
                  />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <TextField source="movilityArea" fullWidth emptyText="-" />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <TextField source="economicArea" fullWidth emptyText="-" />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <TextField source="technologyArea" fullWidth emptyText="-" />
                </CustomItemLabeled>

                {/* <CustomItemLabeled>
                  <TextField
                    source="personalSituation"
                    fullWidth
                    emptyText="-"
                  />
                </CustomItemLabeled> */}
              </Row>
              {/* <Row>
              <CustomItemLabeled>
                <ArrayField source="observations" fullWidth>
                  <Datagrid
                    bulkActionButtons={false}
                    sort={{ field: "createdAt", order: "DESC" }}
                    fullWidth
                  >
                    <TextField
                      sx={{ marginY: 2 }}
                      source="text"
                      helperText={false}
                      rows={4}
                      fullWidth
                      emptyText="-"
                    />
                    <DateField source={"createdAt"} emptyText="-" />                   
                  </Datagrid>
                </ArrayField>
              </CustomItemLabeled>
            </Row>
            <Row>
              <CustomItemLabeled>
                <ArrayField source="comments" fullWidth>
                  <Datagrid bulkActionButtons={false}>
                    <TextField
                      sx={{ marginY: 2 }}
                      source="text"
                      helperText={false}
                      rows={4}
                      fullWidth
                    />
                    <DateField source={"createdAt"} />                   
                  </Datagrid>
                </ArrayField>
              </CustomItemLabeled>
            </Row> */}

              <Row>
                {/* <CustomItemLabeled>
                  <BooleanField source="avastTest" />
                </CustomItemLabeled> */}

                <CustomItemLabeled>
                  <BooleanField source="sexualTest.isCompleted" />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <BooleanField source="sexualTest.anathomyScore" />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <BooleanField source="sexualTest.relationshipsScore" />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <BooleanField source="sexualTest.preventionScore" />
                </CustomItemLabeled>
              </Row>
              <Row>
                <CustomItemLabeled>
                  <BooleanField
                    source="imageRightsConsentAccepted"
                    label="imageRightsConsentAccepted"
                  />
                </CustomItemLabeled>
              </Row>
              { permissions==='admin'?
              <Row>
                <CustomItemLabeled>
                  <BooleanField
                    source="legalTermsAccepted"
                    label="legalTermsAccepted"
                  />
                </CustomItemLabeled>
                <CustomItemLabeled>
                  <BooleanField
                    source="legalTermsAccepted"
                    label="legalTermsAccepted"
                  />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <BooleanField
                    source="isAutogeneratedPassword"
                    label="isAutogeneratedPassword"
                  />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <BooleanField
                    source="accessRequiredInfo"
                    label="accessRequiredInfo"
                  />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <BooleanField
                    source="visibility"
                    label="visibility"
                  />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <BooleanField
                    source="enabled"
                    label="enabled"
                  />
                </CustomItemLabeled>
              
                <CustomItemLabeled>
                  <DateField source="createdAt" />
                </CustomItemLabeled>

                <CustomItemLabeled>
                  <DateField source="updatedAt" />
                </CustomItemLabeled>
              </Row> : null }
            </Column>
            <Column xs={12} sm={12} md={3}>
              <Row>
                <Item xs={12} sm={12} md={12}>
                  <CustomImageField
                    sx={{ width: "100%", marginBottom: 16, borderRadius: 8 }}
                    source="avatar"
                    size="medium"
                    title="avatar"
                  />
                </Item>
                <Item xs={4} sm={4} md={4}>
                  <CustomImageField
                    sx={{ width: "100%", marginBottom: 16, borderRadius: 8 }}
                    source="photos.0"
                    size="medium"
                    title="photos.0"
                  />
                </Item>
                <Item xs={4} sm={4} md={4}>
                  <CustomImageField
                    sx={{ width: "100%", marginBottom: 16, borderRadius: 8 }}
                    source="photos.1"
                    size="medium"
                    title="photos.1"
                  />
                </Item>
                <Item xs={4} sm={4} md={4}>
                  <CustomImageField
                    sx={{ width: "100%", marginBottom: 16, borderRadius: 8 }}
                    source="photos.2"
                    size="medium"
                    title="photos.2"
                  />
                </Item>
              </Row>
              <Row>
                <Item>
                  {/* <ArrayField source="interests">
                  <SingleFieldList linkType="show" resource="interests">
                    <ChipField source={`label.${config.defaultLocale}`} />
                  </SingleFieldList>
                </ArrayField> */}
                  <ReferenceArrayField source="interests" reference="interests">
                    <SingleFieldList linkType="show">
                      <ChipField source={`label.${config.defaultLocale}`} />
                    </SingleFieldList>
                  </ReferenceArrayField>
                </Item>
              </Row>
            </Column>
          </Container>
        </Row>
        <Row>
        </Row>
        </SimpleShowLayout>
        </RecordContextProvider>
        {userA && userB?
        <Row>
          <Row>
            <h1>Plans</h1>
            <Item xs={12} sm={12} md={12}>
              <CustomNestedList resource="plans"  filter={{
                  "all_users": [userA?.id, userB?.id]
                  }} actions={null} perPage={1000} pagination={false} empty={<>No plans</>}>
                <Datagrid rowClick="show" bulkActionButtons={false}>
                <TextField source="name" emptyText="-" />
                {/* <CustomReferenceField
                  link="show"
                  label="Coordinator"
                  source="coordinator.id"
                  reference="users"
                  field={"coordinator.username"}
                /> */}
                <SelectField
                  source="status"
                  choices={[
                    { id: "NOT_STARTED", name: "NOT STARTED" },
                    { id: "STARTED", name: "STARTED" },
                    { id: "FINISHED", name: "FINISHED" },
                    { id: "COMPLETED", name: "COMPLETED" },
                  ]}
                  fullWidth
                  emptyText="-"
                />
                {/* <CustomImageField
                  source="photo"
                  size="small"
                  title="photo"
                  sx={{ height: 60, borderRadius: 10 }}
                  emptyText="-"
                /> */}
                <TextField source="address.ca" emptyText="-" />
                <FunctionField
                  label="Users"
                  render={(record) => `${record.users.length}/${record.maxUsers}`}
                />
                <DateField source="startDate" showTime emptyText="-" />
                </Datagrid>
              </CustomNestedList>
            </Item>
        </Row>
        <Row>
          <h1>Matches</h1>
          <Row>
            <Item xs={12} sm={12} md={12}>
              <CustomNestedList resource="matches" source={"users"} filter={{
                  "all_users": [userA?.id, userB?.id]
                  }} actions={null} perPage={1000} pagination={false}  empty={<>No matches</>}>
                <Datagrid rowClick="show" bulkActionButtons={false}>
                  <TextField source="id" />
                  {/* <ArrayField source="users">
                  <SingleFieldList linkType="show" resource="users">
                    <ChipField source={`username`} />
                  </SingleFieldList>
                </ArrayField> */}
                  <ReferenceArrayField source="users" reference="users">
                    <SingleFieldList linkType="show">
                      <ChipField source={`username`} />
                    </SingleFieldList>
                  </ReferenceArrayField>
                  <TextField source="option" emptyText="-" />
                  <TextField source="type" emptyText="-" />
                  <TextField source="status" emptyText="-" />
                  <BooleanField source="description" fullWidth emptyText="-" />
                </Datagrid>
              </CustomNestedList>
            </Item>
          </Row>
        </Row>
        {/* <Row>
          <h1>Meetings</h1>
          <Row>
            <Item xs={12} sm={12} md={12}>
              <CustomNestedList resource="meetings" source={"users"} actions={null} perPage={1000} pagination={false}  empty={<>No meetings</>}>
                <Datagrid rowClick="show" bulkActionButtons={false}>
                  <TextField source="id" emptyText="-" />
                  <ReferenceField
                    link="show"
                    source="userA.id"
                    reference="users"
                    emptyText="-"
                  />
                  <ReferenceField
                    link="show"
                    source="userB.id"
                    reference="users"
                    emptyText="-"
                  />
                  <TextField source="status" emptyText="-" />
                  <TextField source="reason" emptyText="-" />
                  <BooleanField source="comment" fullWidth emptyText="-" />
                </Datagrid>
              </CustomNestedList>
            </Item>
          </Row>
          <Row>
            <h1>Reports Made</h1>
            <Row>
            <Item xs={12} sm={12} md={12}>
              <CustomNestedList resource="reports" source={"userA"} actions={null} perPage={1000} pagination={false}  empty={<>No reports made</>}>
                <Datagrid rowClick="show" bulkActionButtons={false}>
                  <TextField source="id" emptyText="-" />
                  <ReferenceField
                    link="show"
                    source="userA.id"
                    reference="users"
                    emptyText="-"
                  />
                  <ReferenceField
                    link="show"
                    source="userB.id"
                    reference="users"
                    emptyText="-"
                  />
                  <TextField source="option" emptyText="-" />
                  <TextField source="type" emptyText="-" />
                  <TextField source="status" emptyText="-" />
                  <BooleanField source="description" fullWidth emptyText="-" />
                </Datagrid>
              </CustomNestedList>
            </Item>
          </Row>
          <Row>
            <h1>Reports Received</h1>
            <Row>
              <Item xs={12} sm={12} md={12}>
                <CustomNestedList resource="reports" source={"userB"} actions={null} perPage={1000} pagination={false} empty={<>No reports received</>}>
                  <Datagrid rowClick="show" bulkActionButtons={false}>
                    <TextField source="id" emptyText="-" />
                    <ReferenceField
                      link="show"
                      source="userA.id"
                      reference="users"
                      emptyText="-"
                    />
                    <ReferenceField
                      link="show"
                      source="userB.id"
                      reference="users"
                      emptyText="-"
                    />
                    <TextField source="option" emptyText="-" />
                    <TextField source="type" emptyText="-" />
                    <TextField source="status" emptyText="-" />
                    <BooleanField source="description" fullWidth emptyText="-" />
                  </Datagrid>
                </CustomNestedList>
              </Item>
            </Row>
          </Row>
          </Row>
          <Row>
            <h1>Meeting Reviews</h1>
          <Row>
            <Item xs={12} sm={12} md={12}>
              <CustomNestedList resource="meeting-reviews" source={"user"} actions={null} perPage={1000} pagination={false}  empty={<>No meeting reviews</>}>
                <Datagrid rowClick="show" bulkActionButtons={false}>
                  <TextField source="id" emptyText="-" />
                  <TextField source="answers" emptyText="-" />
                  <ReferenceField
                    link="show"
                    source="meeting.id"
                    reference="meetings"
                    emptyText="-"
                  />
                  <ReferenceField
                    link="show"
                    source="questionnaire.id"
                    reference="questionnaires"
                    emptyText="-"
                  />
                  <ReferenceField
                    link="show"
                    source="report.id"
                    reference="reports"
                    emptyText="-"
                  />
                  <DateField source="createdAt" emptyText="-" />
                </Datagrid>
              </CustomNestedList>
            </Item>
          </Row>
        </Row>
        <Row>
          <h1>Plan Reviews</h1>
          <Row>
            <Item xs={12} sm={12} md={12}>
              <CustomNestedList resource="plan-reviews" source={"user"} actions={null} perPage={1000} pagination={false} empty={<>No plan reviews</>}>
                <Datagrid rowClick="show" bulkActionButtons={false}> 
                  <TextField source="id" emptyText="-" />
                  <TextField source="answers" emptyText="-" />
                  <NumberField source="activityRating" emptyText="-" />
                  <NumberField source="coordinatorRating" emptyText="-" />
                  <ReferenceField
                    link="show"
                    source="plan.id"
                    reference="plans"
                    emptyText="-"
                  />
                  <ReferenceField
                    link="show"
                    source="questionnaire.id"
                    reference="questionnaires"
                    emptyText="-"
                  />
                  <DateField source="createdAt" emptyText="-" />
                </Datagrid>
              </CustomNestedList>
            </Item>
          </Row>
        </Row>
        </Row> */}
        </Row>
        :null}
      </Row>
    </Container>
  );
};

export default Comparison;
